import * as React from "react"
import styled from 'styled-components/macro'

const ComingSoonWrapper = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  background-color: #1e1c29;
  position: absolute;
  /*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%2340444f' fill-opacity='0.35'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");*/
`

const ComingSoonInner = styled.div`
  max-width: 1480px;
  height: 100%;
  padding-left: 80px;
  width: calc(100% - 80px);
  margin: 0 auto;
`

const LeftSide = styled.div`
  display: inline-block;
  width: 38.2%;
  height: 100%;
  vertical-align: top;
  color: #f9f9f9;

  @media screen and (max-width: 900px) {
    width: 80%;
  }

  @media screen and (max-width: 880px) {
    width: 80%;
  }
`

const LeftSideInner = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: left;
  position: relative;

  @media screen and (max-width: 880px) {
    align-items: start;
    margin-top: 20vh;
    text-align: center;
  }
`

const LeftSideContent = styled.div`
  display: inline-block;
  height: fit-content;
`

const TitleText = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 3em;
  font-weight: 600;
  line-height: 1.1em;
  width: 100%;
  margin-bottom: 10px;

  @media screen and (max-width: 880px) {
    font-size: 2em
  }
`

const SubtitleText = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 1em;
  font-weight: 300;
  color: #d5d6e1;
  margin-bottom: 20px;
  letter-spacing: 0.3px;
`

const ActionButtons = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 100%;
  text-align: center;
`

const LaunchAppButton = styled.div`
  width: fit-content;
  padding: 12px 24px;
  background-color: #8fc942;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
`

const RightSide = styled.div`
  display: inline-block;
  width: 61.8%;
  height: 100%;
  vertical-align: top;
`

const RightSideImageWrapper = styled.div`
  display: flex;
  justify-content: left;
  position: relative;
  margin-top: 60px;
  margin-left: 25px;
  height: 90%;
  
  img {
    width: auto;
    height: 100%;
    border-radius: 8px;
  }
`

// markup
const ComingSoon = () => {

  return (
    <ComingSoonWrapper>
      <div id="polyContainer" className="polyContainer" style={{ height: 'calc(100vh)', width: 'calc(100vw + 15px)', position: 'absolute', zIndex: '0' }}>
        <div id="polyOutput" className="polyContainer" style={{ height: '100%', width: '100%' }}>
        </div>
      </div>
      <ComingSoonInner>
        <LeftSide>
          <LeftSideInner>
            <LeftSideContent>
              <TitleText>
                Powerful DEX margin trading coming soon
              </TitleText>
              <SubtitleText>
                Margin and spot trade DeFi bluechips like never before while earning increased APR on held assets - all from your desktop or mobile wallets.
              </SubtitleText>
            </LeftSideContent>
          </LeftSideInner>
        </LeftSide>
      </ComingSoonInner>
    </ComingSoonWrapper>
  )
}

export default ComingSoon
